.search-form {
  width: auto;
  // margin-right: $spacing-m;
  margin-left: auto;

  input:not([type='submit']):not([type='radio']):not([type='checkbox']) {
    // min-width: 13rem;
    // min-height: 2.5rem;
    // padding: $spacing-s $spacing-l $spacing-s $spacing-s;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    height: 100%;
    align-items: center;
    // padding: $spacing-s;
    border: 0;
    color: #fff;
  }

  .form-control-feedback {
    position: absolute;
    z-index: 4;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    pointer-events: none;
    text-align: center;
  }

  .form-control-feedback + .form-control {
    padding-left: 2.375rem;
  }
}
