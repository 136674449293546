.skiplink {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);

  &:focus {
    position: absolute;
    z-index: 101;
    top: 1rem;
    left: 50%;
    overflow: auto;
    width: auto;
    height: auto;
    margin: 0;
    clip: unset;
    transform: translate-x(-50%);
  }
}
