.custom-breadcrumb {
  .breadcrumb-item {
    + .breadcrumb-item {
      &::before {
        @include svg-sprite('icon-chevron-right');
        @include svg-size('icon-chevron-right');
        margin-right: map-get($spacers, 2);
        content: '';
      }
    }
  }
}

.dc-department {
  text-align-last: end;
}
