.custom-card {
  &.shadow {
    &:focus,
    &:active,
    &:hover {
      text-decoration: none;
    }
  }
}

.card-bonus-lane {
	.card-body {
		text-align: left;
		padding-left: 0;
		padding-right: 0;

		h3 {
			margin-bottom: 0;
		}

		small {
			margin-bottom: 0;
		}
	}
}

.card-legend {
	position: relative;
	padding: 1em;
	border: none;

	h2 {
		font-size: 1.7em;
		text-align: left;
	}

	&.card-legend-bonus {
		color: $bonus-color;
		background: #ffebd9;

		&:after {
			position: absolute;
			width: 0;
			height: 0;
			right: -20px;
			bottom: 20px;
			border-top: 20px solid transparent;
			border-bottom: 20px solid transparent;
			border-left: 20px solid #ffebd9;
			content: '';
		}
	}
}
