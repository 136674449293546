.shop-card {
  .card-image > img {
    width: auto;
    height: 77px;
  }

  .card-text > p {
    font-family: $font-family-medium;
  }
}
