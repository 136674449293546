header {
  ul,
  li,
  a {
    display: flex;
    align-items: center;
  }

  ul {
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style-type: none;

    a {
      padding: map-get($spacers, 2) map-get($spacers, 3);
    }
  }

  .store-nav {
    margin-right: 12px;

    a {
      color: $primary;

      &:hover,
      &:active,
      &:focus {
        color: $black;
      }
    }
  }

  .header-logo {
    height: 42px;
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    .brand-general {
      position: absolute;
      width: 100%;
    }

    .brand-shop {
      // width: 100%;
      position: relative;
      // left: 0;
      // right: 1em;
      bottom: -0.7em;
      padding-left: 50px;
      text-align: right;
      font-size: 0.8em;
      min-width: 235px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
  }

  @include media-breakpoint-only(xs) {
    .header-logo {
      .brand-shop {
        white-space: nowrap;
        padding: 0;
        max-width: 50vw;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }

      .brand-general {
        left: 0;
        right: 0;
      }

      .svg--logo-icon {
        max-height: 30px;
        max-width: 30px;
        position: relative;
        top: -10px;
        margin: 0 auto;
      }
    }
  }
}
