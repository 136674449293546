.filters {
  span,
  a {
    display: inline-flex;
    width: 100%;
    align-items: baseline;
    padding: map-get($spacers, 1) map-get($spacers, 2);
  }

  a {
    cursor: pointer;

    &:hover,
    &:active,
    &:focus,
    &:visited {
      background-color: $light;
      color: $black;
      text-decoration: none;
    }

    &[data-count]::after {
      margin-left: map-get($spacers, 1);
      color: $gray-500;
      content: '(' attr(data-count) ')';
      font-size: 0.8em;
    }

    &.active {
      background-color: rgba($primary, 0.1);

      &::before {
        order: 3;
        margin-left: auto;
        color: $red;
        content: 'x';
        font-weight: bold;
      }
    }
  }

  [data-toggle] {
    color: $primary;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus,
    &:visited {
      color: $primary;
      text-decoration: underline;
    }
  }
}
