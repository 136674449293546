footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 290px;
  padding-bottom: 6em !important;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .footer-menu {
    li {
      a {
        display: inline-block;
        padding: .5em;
      }
    }
  }

  @include media-breakpoint-only(xs) {
    height: 780px;

    .footer-menu {
      display: block !important;
      border-top: 1px solid rgba(0, 0, 0, .1);
      margin-top: 1em;

      li {
        display: block;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        white-space: nowrap;
        text-align: center;

        a {
          display: inline-block;
          padding: .4em 0 !important;
        }
      }
    }

    .nix {
      font-size: 0.8em;
      margin-top: 1.8em;
    }

    .col-logo {
      text-align: center;

      .svg--logo {
        margin-top: 1em;
      }
    }
  }
}
