.product-detail {
  .product-image {
    flex: 0 0 30rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  .product-details {

    @include media-breakpoint-only(xs) {
      h1 {
        font-size: 1.5rem;
      }

      .price-box {
        text-align: right;
      }
    }

    .add-to-box {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .price-box {
        float: left;
        width: 2em;
      }

      .buttons-set {
        float: left;
        width: 50%;
      }

    }

    .badge-bonus {
      font-size: 1em;
    }

    .unit {
      font-size: 1rem;
    }

    .price-box {
      display: block;
      position: static;
      font-size: 4em;

      .current-price {
        display: inline-block;
        font-family: "hamburg-boldregular", Arial, Sans-serif;
      }

      .old-price-wrapper {
        display: inline-block;
        text-align: left;
        width: auto;
        font-size: .5em;
        top: -.8em;
        font-family: "hamburg-lightregular", Arial, Sans-serif;
      }
    }
  }

  .product-extras {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;

    .highlights {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;

      > div {
        ul {
          margin-bottom: 0;
        }
      }
    }
  }

  .product-details,
  .product-extras {
    ul {
      padding: 0;
      margin: 0 0 1.5rem;
      list-style-type: none;

      li {
        position: relative;
        padding-left: 1.5em;

        &::before {
          position: absolute;
          top: .5em;
          left: 0;
          display: block;
          width: 0.5rem;
          height: 0.5rem;
          margin-right: 1rem;
          background-color: $primary;
          border-radius: 100%;
          content: '';
        }
      }
    }
  }

  .product-contents {
    > div {
      > div {
        // stylelint-disable-next-line
        + div {
          margin-top: 2rem;
        }
      }
    }

    table {
      @extend .table;
      width: 100%;
      margin-top: 1rem;
      border-collapse: unset;

      thead {
        th {
          padding: 0.25rem 0 1rem;
          border-bottom: 0;
        }
      }

      td {
        padding: 0.25rem 0;
      }
    }
  }

  .other-products {
    > .col {
      text-align: center;
      max-width: 140px;
    }
  }
}

.ah-list {
  list-style-type: none;

  li::before {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 1rem;
    background-color: $primary;
    border-radius: 100%;
    content: '';
  }
}
