header {
	.brand-easy {
		display: none;
	}
}

body.ah-easy {

	header {
		.brand-general {
			display: none;
		}

		.brand-easy {
			display: block;
			position: absolute;
			right: 0;
		}

		.brand-shop {
			display: block;
			padding-left: 0;
			padding-right: 48px;
			white-space: nowrap;
			min-width: 160px;
		}

		.svg--logo-icon-easy {
			fill: #fff;
		}
	}


	.hero-ah-easy {
		position: relative;
		color: #fff;
		background-image: url('/img/custom/easy-header.png');
		background-color: #009fdf;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: right center;
		overflow: hidden;
		font-size: 1.25em;

		ul {
			li {
				margin-bottom: .5em;
			}
		}

		.iphone {
			position: absolute;
			bottom: -2rem;
			max-width: 360px;
		}

		.laptop-animation {
			position: absolute;
			pointer-events: none;
			width: 300px;
			height: 150px;
			bottom: 0;

			.svg--laptop {
				position: relative;
				max-width: 300px;
				height: auto;
				z-index: 2;
				transform: scale(1.1);
			}

			.laptop-screen {
			    overflow: hidden;
			    height: 135px;
			    position: absolute;
			    top: 0;
			    left: 0;
			    right: 0;

				.laptop-content {
					position: absolute;
					left: 50%;
					top: 0;
					z-index: 1;
					transform: translateX(-50%);
					animation: animatelaptopcontent 8s infinite;
					transition: ease-in;
				}
			}
		}
	}

	.hero-ah-easy-secondary {

		.col-lg-5 {
			.ipad-medewerker {
				position: absolute;
				bottom: -2rem;
			}
		}
	}

	.hero-ah-easy-secondary {
		background: #eef6f7;
	}

	.row-ah-easy-faq {
		.card {
			border: none;

			.card-header {
				h5 {
				  	font-family: 'hamburg-boldregular';
				}

				.btn-link {
					text-align: left;

					i.fas {
						position: relative;
						top: 4px;
					}

					&[aria-expanded='true'] {
						i.fas {
							transform: none;

							&.fa-plus:before {
								content: "\F068";
							}
						}
					}
				}
			}

			.card-body {
				padding-left: 2.4rem !important;
			}
		}
	}
}

@keyframes animatelaptopcontent {
	0% { top: 0; }
	100% { top: -800px; }
}

@include media-breakpoint-down(md) {
	body.ah-easy {
		header {
			.header-logo {
				.brand-shop {
					white-space: nowrap;
			        padding: 0;
				}

				.brand-easy {
					left: 0;
					right: 0;
				}

				.svg--logo-icon-easy {
					max-height: 30px;
					max-width: 30px;
					position: relative;
					top: -10px;
					margin: 0 auto;
				}
			}
		}

		.ah-easy-info-block {
			h1, h2, h3, h4, h5, h6 {
				text-align: center;
			}

			ul {
				display: block;
				margin: 1em 0 0;
				padding: 0;
				list-style: none;

				li {
					display: block;
					margin: 0 0 1em;
					padding: 0;
					list-style: none;
					text-align: center;
				}
			}
		}

		.hero-ah-easy {
			background-image: none;
		}

		.hero-ah-easy-secondary {

			.col-lg-5 {
				padding-bottom: 100px;

				.ipad-medewerker {
					left: -15px;
					right: -15px;
					width: calc(100vw - 30px);
					max-width: calc(100vw - 30px);
				}
			}
		}

	}
}

@media screen and (max-width: 576px) {
	body.ah-easy {
		.hero-ah-easy {
			.iphone {
				position: relative;
			}
		}
	}
}

@media screen and (max-width: 768px) and (min-width: 576px) {
	body.ah-easy {
		.hero-ah-easy-secondary {
			.col-lg-5 {
				padding-bottom: 260px;
			}
		}
	}
}
