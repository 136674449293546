$black: #000; // needs to be set, due to following variables

$primary: #02ade6;
$secondary: #f35d21;

$twitter: #02ade6;

$light-green: #eff6dd;
$light-blue: #eef6f7;
$light-pink: #fce2e2;
$light-purple: #ede3ef;
$light-brown: #e6e3e1;
$light-yellow: #fef7e6;
$light-grey: #f7f7f7;

$custom-grey: #acacac;

$btn-quantity: #ddd;

$bonus-color: #f35d21;
$new-color: #f91077;

$theme-colors: (
  'twitter': $twitter,
  'light-green': $light-green,
  'light-blue': $light-blue,
  'light-pink': $light-pink,
  'light-purple': $light-purple,
  'light-brown': $light-brown,
  'light-yellow': $light-yellow,
  'light-grey': $light-grey,
  'custom-grey': $custom-grey,
  'bonus': $bonus-color,
  'new': $new-color,
  'btn-quantity': $btn-quantity,
);
