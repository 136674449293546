.card-green {
  background-color: #eff6dd;
}

.card-blue {
  background-color: #eef6f7;
}

.card-pink {
  background-color: #fce2e2;
}

.card-purple {
  background-color: #ede3ef;
}

.card-brown {
  background-color: #e6e3e1;
}

.card-yellow {
  background-color: #fef7e6;
}
