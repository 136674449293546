.customer-service {
  .cs-tabs {
    a[aria-selected='true'] {
      color: $primary;
    }

    a[aria-selected='false'] {
      margin-bottom: 0;
      background-color: #f8f9fa;
    }
  }
}


.btn-link {
  i {
    transition: all 0.5s;
  }

  &[aria-expanded='true'] {
    i {
      transform: rotate(180deg);
    }
  }
}


