.timeline-deliver-method {
	padding: 2em;
	text-align: center;
}

.timeline-address {
	padding: 2em;
	text-align: center;
}

.timeline-row {
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
	margin-bottom: 2em;

	> li {
		display: inline-block;
		width: 10%;
		height: 4em;
		float: left;
		line-height: 4em;
		vertical-align: middle;

		&:first-child {
			width: 15%;
			text-align: left;
		}

		&:last-child {
			width: 15%;
			text-align: right;
		}

		&.timeline-date {
			position: relative;
			border-right: 1px solid #f0ece6;
			text-align: center;

			span {
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}

			&.timeline-date-unavailable {
				color: #f0ece6;
			}

			&.timeline-date-current {
				span {
					left: -0.5em;
					right: -0.5em;
					top: -0.5em;
					bottom: -0.5em;
					border: 1px solid #f0ece6;
					line-height: 3.6em;
					font-size: 1.5em;
					background: #fff;
					z-index: 1;
					box-shadow: 0 0.25rem 1rem rgba($black, 0.04);
					color: $primary;
				}
			}

			&.timeline-date-available {
				cursor: pointer;

				&:hover {
					color: $primary;
				}
			}
		}
	}
}

.timeline-table {
	width: 100%;
	padding: 1em;
	overflow: hidden;

	tr {
		th {
			position: relative;

			&:first-child,
			&:last-child {
				width: 5%;
			}

			&:not(:last-child):after {
				position: absolute;
			    content: "";
			    height: 3000px;
			    background-color: #f0ece6;
			    width: 1px;
			    right: 0px;
			    top: 22px;
			    z-index: 1;
			}

			&.timeline-header {
				min-width: 80px;

				&:not(:last-child) {
					border-bottom: 1px solid #f0ece6;
				}

				> span {
					position: relative;
					left: -25%;
				}
			}
		}

		td {
			position: relative;
			padding: .5em;

			.timeblock {
				position: relative;
				// height: 4.5em;
				background: lighten($custom-grey, 20);
				border-radius: .5em;
				text-align: center;
				z-index: 2;
				padding: .3em 0;

				&.timeblock-available {
					border: 1px solid lighten($primary, 20);
					color: lighten($primary, 20);
					background: #fff;

					&:hover {
						border: 1px solid $primary;
						color: $primary;
						background: #fff;
						cursor: pointer;
					}
				}

				&.timeblock-unavailable {
					background: lighten($custom-grey, 20);
					color: darken($custom-grey, 30);
				}

				&.timeblock-selected {
					color: #fff;
					background: $primary;
					border: none;
				}

				.timeblock-static {
					line-height: 3.2em;
					vertical-align: middle;
				}

				.timeblock-timerange {
					.timeblock-timerange-line {
						display: inline;
					}
				}

				.timeblock-price {
					font-weight: bold;
					font-size: 1.1em;
				}
			}

			&[colspan="1"] {
				.timeblock {
					padding: .3em .4em;

					.timeblock-timerange {
						font-size: .8em;

						.timeblock-timerange-line {
							display: block;
							width: 100%;
							line-height: .8em;
						}
					}

					.timeblock-price {

					}
				}
			}
		}
	}


}

ul {
	.timeblock {
		position: relative;
		height: 4.5em;
		background: lighten($custom-grey, 20);
		border-radius: .5em;
		text-align: center;
		z-index: 2;
		padding: .3em 0;

		&.timeblock-available {
			border: 1px solid lighten($primary, 20);
			color: lighten($primary, 20);
			background: #fff;

			&:hover {
				border: 1px solid $primary;
				color: $primary;
				background: #fff;
				cursor: pointer;
			}
		}

		&.timeblock-unavailable {
			background: lighten($custom-grey, 20);
			color: $custom-grey;
		}

		&.timeblock-selected {
			color: #fff;
			background: $primary;
			border: none;
		}

		.timeblock-static {
			line-height: 3.2em;
			vertical-align: middle;
		}

		.timeblock-timerange-line {
			display: inline;
		}

		.timeblock-price {
			display: inline;
			font-weight: bold;
			font-size: 1.1em;
		}
	}
}
