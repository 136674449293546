.lane-overview {
	.card-body {
		transition: background .1s ease-in;
		&:hover {
			background: #ecf7fd;
		}
	}
}

.lane-title {

}

.lane-subtitle {
	margin: 0;
}

.lane-description {
	font-size: .9em;
	color: $custom-grey;
	margin: 0;
}
