.products-grid {
	align-items: stretch;
	align-content: stretch;

	&:after {
		content: "";
		flex: auto;
	}

	> .col {
		flex: 1 1 15rem;
		margin-bottom: 2em;
		max-width: 375px;

		@include media-breakpoint-only(xs) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			max-width: 100%;
		}

	}
}

.card-product {
	height: 100%;
	margin-bottom: 3em;
	border: none;

	@include media-breakpoint-only(xs) {
		margin-bottom: 0;

		.product-title {
			text-align: center;
			width: 100%;
			position: static;
		}
	}

	.card-img-top {

		.badge-bonus,
		.badge-new {
			position: absolute;
			left: 0;
			bottom: 2em;
			font-size: .8em;
		}

		.text-bonus {
			position: absolute;
			left: 0;
			bottom: 0;
			font-size: .8em;
			font-family: Arial;
		}

		.property-icons {
			position: absolute;
			left: 0;
			bottom: 4em;
			font-size: .8em;

			svg {
				margin-right: .25rem !important;
				max-height: 24px;
				max-width: 24px;
				width: auto;

				&.svg--gall {
					max-width: 48px;
				}
			}
		}

		.btn-favorite {
			position: absolute;
			left: 0;
			top: 0;

			&.active {
				color: red;
			}

			&.inactive {
				color: $custom-grey;

			}

			&:hover {
				transform: scale(1.1);
				transform-origin: center;
			}
		}

		.banner {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			.banner-text {
				display: inline-block;
				padding: 0.3em 0.5em;
				background: #02ade6;
				transform: rotate(-19.9deg);
				transform-origin: top left;
				font-size: 0.8em;
				color: #fff;
				font-weight: bold;
				position: relative;
				text-align: center;
				opacity: .9;
			}

			.banner-text-dark {
				background: #262626;
			}
		}

	}

	.card-body {
		position: relative;
	}

	&.card-product-unavailable,
	&.card-product-temporarily-unavailable {
		opacity: .6;

		img {
			filter: grayscale(1);
		}
	}
}
