.brands-overview {

  ul.nav-brands {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;

    > li {
      width: 20%;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;


    }

    @include media-breakpoint-only(xs) {
      display: block;
      height: auto !important;
      margin: 0;
      padding: 0;

      li {
        display: block;
        width: 100%;
        padding: .3em;
        text-align: center;
        font-stretch: 1.3em;
      }
    }
  }

}
.brands-alphabet {

}
