.banners {
  .text {
    .block,
    .row {
      height: 100%;

      .col {
        display: flex;
        flex-flow: column;
        place-content: space-between;

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .block {
    @extend .col-12;
  }

  .row-cols-lg-2 {
    h2 {
      font-size: $h4-font-size;
    }
  }

  .row-cols-lg-3 {
    h2 {
      font-size: $h5-font-size;
    }
  }
}
