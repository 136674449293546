.heading-banner {

  &.heading-banner-home {
    overflow: hidden;
    height: 300px;
  }

  &.slick-initialized {
    overflow: visible;
  }

  .header-slide {
    display: block;
    height: 300px;
    // width: 100vw;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &:hover {
      text-decoration: none;
    }

    .header-title {
      padding: 2em;
      text-align: right;
      color: #fff;
      text-shadow: 1px 1px 5px #000024;
      font-size: 36px;

      @include media-breakpoint-up(xl) {
        width: 50%;
        position: relative;
        left: 50%;
      }

      small {
        display: block;
        font-size: 19px;
        margin-top: .5em;
      }

      @include media-breakpoint-between(lg, xl) {
        font-size: 26px !important;

        small {
          font-size: 16px !important;
        }
      }

      @include media-breakpoint-down(lg) {
        font-size: 18px;
        text-align: center;

        small {
          font-size: 14px !important;
        }
      }

    }
  }

  .slick-dots {
    padding: .5em 0;
    text-align: center;

    li {
      display: inline-block;;

      button {
        background-color: $primary;
        border: none;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        overflow: hidden;
        text-indent: -1000px;
        opacity: .6;
        margin: 0 .2em;
      }

      &.slick-active {
        button {
          opacity: 1;
        }
      }
    }

  }

  @include media-breakpoint-only(xs) {

    &.heading-banner-home {
      height: 300px;
      background: rgb(18,174,237);
      background: radial-gradient(circle, rgba(18,174,237,1) 0%, rgba(0,159,223,1) 39%, rgba(26,115,150,1) 100%);

      .region-heading-image {
        img {
          display: none;
        }
      }
    }
  }
}

.heading {
  .banner-control {
    position: absolute;
    bottom: 25px;
    left: 30px;
    right: 30px;

    .search-form {
      float: left;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }

    .btn {
      border-radius: 0;
    }

    @include media-breakpoint-between(md, lg) {
      .btn-catalog {
        display: inline-block;
        width: 40% !important;
      }
    }

    @include media-breakpoint-down(sm) {
      .btn-catalog {
        display: block;
        margin: 0 0 5px !important;
        width: 100% !important;
      }
    }

  }
}
