.buttons-set {
  position: absolute;
  bottom: 1.5em;
  right: .5em;
  height: 3em;
  width: 3em;
  overflow: hidden;
  text-align: right;

  @include media-breakpoint-only(xs) {
    position: static;
    width: 100%;
    text-align: center;
    font-size: 1.3em;
    right: 0;
  }


  &.buttons-set-open {
    width: 100%;

    .input-quantity,
    .btn-quantity {
      display: inline-block;
      margin: 0 .1em;
    }
  }

  form {
    display: block;
    float: right;
    background: #fff;
    padding: .5em;
    border-radius: 2em;

    @include media-breakpoint-only(xs) {
      float: none;
      display: inline-block;
      width: 100%;
      padding: 0;
      margin-top: .5em;
    }


    .input-quantity {
      display: none;
      font-weight: bold;
      width: 3em;
      height: 2em;
      text-align: center;
      border: 1px solid #ccc;
      border-radius: 2em;
      color: #111;
    }

    .btn-quantity {
      display: none;
      border: none;
      border-radius: 2em;
      font-weight: bold;
      width: 2em;
      height: 2em;


      &[data-action="increase-quantity"] {
        display: inline-block;
      }
    }
  }

  &.buttons-set-bonus {
    form {
      .btn-quantity {
        color: #fff;
        background-color: $bonus-color !important;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .add-to-box {
    .buttons-set {
      position: relative;
      bottom: 0;
      left: 50%;
      width: 100% !important;
      height: 4em;
      transform: translateX(-50%);

      form {
        width: 100%;

        .btn-quantity {
          width: 100%;
          height: 3em;
          border-radius: 5px;
        }
      }
    }
  }

  .buttons-set-open {
    form {
      display: flex;
      justify-content: space-between;

      .btn-quantity {
        width: 2em !important;
        height: 2em !important;
        border-radius: 2em !important;

        span {
          display: none !important;
        }
      }
    }
  }
}

.table-cart {

  th {
    font-size: 1.4em;
    padding: 0.5em 1em;
    border-top: none;
  }

  .col-name,
  .col-price {
    font-size: 1.2em;
    vertical-align: middle;
    padding: 0.5em 1em;
  }

  .col-total {
    font-size: 1.5em;
    padding: 0.5em 1em;
  }

  .col-quantity {
    width: 10em;

    .buttons-set {
      position: relative;
      bottom: 0;
      right: 0;

      form {
        display: inline-block;
        float: none;
      }
    }
  }

  @include media-breakpoint-only(xs) {
    font-size: 1rem;
    margin-top: 2em;

    th {
      padding: 0;
      font-size: 1em;
    }

    .buttons-set {
      font-size: 1em;
    }

    .col-quantity {
      width: 0;
      padding: 0;
    }

    .col-name {
      width: 70%;
      font-size: 1em;
    }

    .col-total,
    .col-price {
      font-size: 1em;
      padding: .1em .5em;
    }
  }
}

#cart-inline-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  // top: calc(100vh - 5em);
  top: 100vh;
  background: rgba(0, 0, 0, .2);
  transition: background .3s ease-in;
  z-index: 10;

  #cart-inline {
    position: relative;
    top: 1em;
    margin: 2em;
    padding: 2em;
    background: #fff;
    transition: top .3s ease-in;

    @include media-breakpoint-only(xs) {
      margin: 0;
      padding: .5em;
    }
  }

  &.cart-inline-open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .2);
    box-shadow: 0 0.1rem 0.6rem rgba(0, 0, 0, 0.12) !important;
    overflow-y: scroll;

    #cart-inline {
      top: 10vh;
      transition: top .3s ease-in;

      @include media-breakpoint-only(xs) {
        top: 76px;
      }
    }

    #cart-inline-toggle-btn {
      display: none;
    }
  }

  &.cart-inline-closed {
    position: fixed;
    height: 5em;
    text-align: center;
    background: rgba(0, 0, 0, 0);

    #cart-inline {
      top: 100vh;
      transition: top .3s ease-in;
    }

    #cart-inline-toggle-btn {
      position: absolute;
      top: -5em;
      left: 50%;
      display: inline-block;
      height: 4em;
      line-height: 4em;
      vertical-align: middle;
      border: 1px solid #dee2e6;
      text-align: center;
      background: #fff;
      transform: translate(-50%,0);
      cursor: pointer;
      box-shadow: 0 0.1rem 0.6rem rgba(0, 0, 0, 0.12) !important;

      &:hover {
        box-shadow: 0 0.1rem 0.6rem rgba(0, 0, 0, 0.3) !important;
      }

      .svg--icon-cart {
        position: absolute;
        left: 1em;
        top: 1em;
      }

      .badge {
        position: absolute;
        left: 2.5em;
        top: 2.5em;
      }

      .total {
        padding-left: 3em;
        padding-right: 1em;
        font-size: 1.3em;
        font-family: 'hamburg-lightregular';
        text-align: right;

        .price {
          font-family: 'hamburg-boldregular';
        }
      }
    }
  }

}

.icon-purchase-stamps {
  max-width: 70px;
  height: auto;
  display: inline-block;
}
