html,
body {
  min-height: 100vh;
  position: relative;
}

body {
	padding-bottom: 290px;

  	@include media-breakpoint-only(xs) {
		padding-bottom: 800px;
  	}

}

main {
  display: flex;
  flex-wrap: wrap;
}
