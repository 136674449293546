.price-box {
  right: 0;
  bottom: 0;

  @include media-breakpoint-only(xs) {
    position: relative;
    font-size: 2.5em;
  }

  sup {
    top: -0.75em;
    left: -0.15em;
    font-size: 50%;
  }

  .unit {
    position: relative;
    left: .5em;
    font-size: 0.5em;
    font-weight: normal;
    font-family: arial;

    @include media-breakpoint-only(xs) {
      position: absolute;
      left: auto;
      right: 0;
      bottom: 100px;
      font-size: 0.4em;
      text-align: right;
    }

  }

  .old-price-wrapper {
    position: relative;
    display: block;
    width: 100%;
    font-size: 1em;
    text-align: right;

    .old-price {
      position: relative;
      color: #343a40;
      font-family: $font-family-medium;

      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: #343a40;
        content: '';
        transform: translateY(-50%) rotate(-7deg);
      }
    }
  }
}
