@font-face {
  font-display: swap;
  font-family: 'hamburg-lightregular';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/hamburg-light-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/hamburg-light-webfont.woff2') format('woff2'), url('/fonts/hamburg-light-webfont.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'hamburg-boldregular';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/hamburg-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/hamburg-bold-webfont.woff2') format('woff2'), url('/fonts/hamburg-bold-webfont.woff') format('woff');
}


@font-face {
  font-display: swap;
  font-family: 'hamburg-medium';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/hamburg-medium-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/hamburg-medium-webfont.woff2') format('woff2'), url('/fonts/hamburg-medium-webfont.woff') format('woff');
}
