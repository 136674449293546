figure.barcode {
  display: inline-block;

  img {

  }

  figcaption {
    text-align: center;
  }
}
