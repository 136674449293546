.heading {
  .overlay {
    top: 2rem;
    padding: 0 30px;

    @include media-breakpoint-up(lg) {
      top: 3rem;
      right: 3rem;
      max-width: 35rem;
      padding: 0;
    }
  }
}

