@include media-breakpoint-down(xs) {
  .no-col-xs-gutters {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-down(sm) {
  .no-col-sm-gutters {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-down(md) {
  .no-col-md-gutters {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-down(lg) {
  .no-col-lg-gutters {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-down(xl) {
  .no-col-xl-gutters {
    padding-right: 0;
    padding-left: 0;
  }
}
