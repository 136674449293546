.highlight-product {
	h2 {
		a:hover {
			text-decoration: none;
		}
	}

	.text {
		.read-more {
			text-decoration: underline;;
		}
	}
}
