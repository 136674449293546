.post-readmore {
  // @extend .btn;

  &::after {
    content: '>';
  }
}

.btn-plus {
  width: 30.5px;
  height: 30.5px;

  span {
    top: 50%;
    left: calc(50%);
    transform: translate(-50%, -50%);
  }
}

.btn-primary-custom {
  min-width: 120px;
  border-radius: 0;
  @include media-breakpoint-down(lg) {
    width: 50%;
  }
}

.btn-nav-cart {
  position: relative;

  .badge {
    position: absolute;
    right: 0.2em;
    bottom: 0.2em;
  }
}
