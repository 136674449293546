.product-banner {
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.product-overview {
  .filters {
    .filter {
      .filter-option {
        line-height: 1em;
        vertical-align: middle;

        svg {
          position: relative;
          display: inline-block;
          width: 1em;
          top: 2px;
          height: 1em;
        }
      }
    }
  }

  .overview {
    @include media-breakpoint-up(md) {
      flex: 0 0 calc(100% - 15rem);
    }
    .grid {
      justify-content: flex-start;
    }
  }
}
